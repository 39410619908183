// import "dotenv/config";
import "./App.css";
import firebase from "firebase/compat/app";
import { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthContextProvider } from "./context";
import Home from "./pages/Home";
import DJ from "./pages/DJ";
import Navbar from "./components/NavBar";
import Vote from "./pages/Vote";
import Gallery from "./pages/Gallery";
import Battle from "./pages/Battle";
import BestCostume from "./pages/BestCostume";

function App() {
  // const firebaseConfig = {
  //   apiKey: process.env.REACT_APP_apiKey,
  //   authDomain: process.env.REACT_APP_authDomain,
  //   projectId: process.env.REACT_APP_projectId,
  //   storageBucket: process.env.REACT_APP_storageBucket,
  //   messagingSenderId: process.env.REACT_APP_messagingSenderId,
  //   appId: process.env.REACT_APP_appId,
  // };
  const firebaseConfig = {
    apiKey: "AIzaSyC5MjfOBUOBPcl1OqS7L_qNDFk4mbf8xB4",
    authDomain: "bursdival.firebaseapp.com",
    projectId: "bursdival",
    storageBucket: "bursdival.appspot.com",
    messagingSenderId: "786777047708",
    appId: "1:786777047708:web:17212d1c8762d242326359",
    measurementId: "G-V9VNGPRYH3",
  };

  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);

  useEffect(() => {
    document.title = "BURSDIVAL 2024";
  }, []);

  return (
    <AuthContextProvider>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/dj" element={<DJ />} />
          <Route path="/vote" element={<Vote />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/costume" element={<BestCostume />} />
          <Route path="/battle" element={<Battle />} />
        </Routes>
      </BrowserRouter>
    </AuthContextProvider>
  );
}

export default App;
