'use client'

import React, { useContext, useEffect, useState } from "react"
import { db, storage } from "../services/firebase"
import {
  collection,
  query,
  where,
  getDocs,
  addDoc,
  doc,
  updateDoc,
  increment,
} from "firebase/firestore"
import { AuthContext } from "../context"
import { getDownloadURL, ref, uploadBytes } from "firebase/storage"
import { Camera, Plus, Upload, X } from "phosphor-react"
import { ArrowsDownUp } from "phosphor-react"

interface Nomination {
  id: string
  name: string
  votes: number
  imageUrl?: string
  timestamp: number
}

export default function VotingList() {
  const [nominations, setNominations] = useState<Nomination[]>([])
  const [name, setName] = useState("")
  const [isLoadingVote, setIsLoadingVote] = useState(false)
  const [image, setImage] = useState<File | null>(null)
  const [isUploading, setIsUploading] = useState(false)
  const [activeTab, setActiveTab] = useState<'vote' | 'upload'>('vote')
  const { user } = useContext(AuthContext)
  const [sortBy, setSortBy] = useState<'timestamp' | 'votes'>('timestamp')
  const [remainingVotes, setRemainingVotes] = useState(3)

  useEffect(() => {
    const fetchNominations = async () => {
      const nominationsRef = collection(db, "nominations2024")
      const querySnapshot = await getDocs(nominationsRef)
      const nominationsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as Nomination[]
      nominationsData.sort((a, b) => b.votes - a.votes)
      setNominations(nominationsData)
    }
    fetchNominations()
  }, [])

  useEffect(() => {
    const fetchVotesAndNominations = async () => {
      if (user) {
        const voteCollectionRef = collection(db, "votes24")
        const userVotesQuery = query(voteCollectionRef, where("userId", "==", user.uid))
        const userVotesSnapshot = await getDocs(userVotesQuery)
        const userVoteCount = userVotesSnapshot.size
        setRemainingVotes(3 - userVoteCount)
      }
      // ... existing nomination fetching code ...
    }
    fetchVotesAndNominations()
  }, [user])

  const handleVote = async (nominationId: string) => {
    if (!user) {
      alert("Du må være logget inn for å stemme.")
      return
    }

    const voteCollectionRef = collection(db, "votes24")
    const userVotesQuery = query(
      voteCollectionRef,
      where("userId", "==", user.uid)
    )
    const userVotesSnapshot = await getDocs(userVotesQuery)
    const userVoteCount = userVotesSnapshot.size

    if (userVoteCount >= 3) {
      alert("Du har brukt alle dine stemmer (3 stemmer).")
      return
    }

    try {
      setIsLoadingVote(true)
      await addDoc(voteCollectionRef, {
        userId: user.uid,
        nominationId,
        timestamp: new Date(),
      })
      const nominationRef = doc(db, "nominations2024", nominationId)
      await updateDoc(nominationRef, {
        votes: increment(1),
      })
      setNominations(prevNominations =>
        prevNominations.map(nom =>
          nom.id === nominationId ? { ...nom, votes: nom.votes + 1 } : nom
        )
      )

      // Update remaining votes
      setRemainingVotes(prev => prev - 1); // Decrease remaining votes by 1
    } catch (error) {
      console.error("Error updating votes:", error)
    } finally {
      setIsLoadingVote(false)
    }
  }

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    setImage(file || null)
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!image) {
      alert("Vennligst velg et bilde.")
      return
    }

    if(!name) {
      alert("Vennligst skriv inn navn.")
      return
    }

    let imageUrl = ""
    try {
      setIsUploading(true)
      const imageRef = ref(storage, `nomination_images/2024/${image.name}`)
      await uploadBytes(imageRef, image)
      imageUrl = await getDownloadURL(imageRef)

      const nominationsRef = collection(db, "nominations2024")
      const nominationData = {
        name,
        imageUrl,
        votes: 0,
        timestamp: Date.now(),
      }

      const docRef = await addDoc(nominationsRef, nominationData)

      setNominations(prevNominations => [
        ...prevNominations,
        { ...nominationData, id: docRef.id },
      ])

      setName("")
      setImage(null)
      alert("Nominasjon lastet opp!")
    } catch (error) {
      console.error("Error adding nomination:", error)
      alert("Det oppstod en feil ved opplasting av nominasjonen.")
    } finally {
      setIsUploading(false)
    }
  }

  const sortedNominations = [...nominations].sort((a, b) => {
    if (sortBy === 'timestamp') {
      return b.timestamp - a.timestamp;
    } else {
      return b.votes - a.votes;
    }
  });

  const toggleSort = () => {
    setSortBy(prevSort => prevSort === 'timestamp' ? 'votes' : 'timestamp');
  };

  return (
    <>
    <div className="max-w-3xl mx-auto p-4">
      <h1 className="text-3xl font-bold text-center mb-4 text-white">Beste kostyme</h1>

      <div className="mb-4">
        <div className="flex bg-gray-100 rounded-lg p-2">
          <button
            className={`flex-1 py-2 px-4 rounded-md transition-colors duration-200 ease-in-out ${
              activeTab === 'vote'
                ? 'bg-white text-blue-600 shadow-md'
                : 'text-gray-600 hover:bg-gray-200'
            }`}
            onClick={() => setActiveTab('vote')}
          >
            Stem
          </button>
          <button
            className={`flex-1 py-2 px-4 rounded-md transition-colors duration-200 ease-in-out ${
              activeTab === 'upload'
                ? 'bg-white text-blue-600 shadow-md'
                : 'text-gray-600 hover:bg-gray-200'
            }`}
            onClick={() => setActiveTab('upload')}
          >
            Nominer
          </button>
        </div>
      </div>

      {activeTab === 'vote' && (
        <div className="min-h-screen">
          <div className="flex justify-between items-center mb-4">
            <p className="text-sm text-white">Du har {remainingVotes} {remainingVotes === 1 ? 'stemme' : 'stemmer'} igjen</p>
            <button
              onClick={toggleSort}
              className="flex text-sm items-center bg-gray-200 text-gray-800 py-2 px-3 rounded-md hover:bg-gray-300 transition-colors duration-200 ease-in-out"
            >
              <ArrowsDownUp className="w-4 h-4 mr-2" />
              {sortBy === 'timestamp' ? 'Nyeste' : 'Stemmer'}
            </button>
          </div>
          <ul className="space-y-4">
            {sortedNominations.map((nomination) => (
              <li key={nomination.id} className="bg-white rounded-lg shadow-md p-4 transition-shadow hover:shadow-lg">
                <h3 className="text-lg font-semibold mb-2">{nomination.name}</h3>
                <p className="text-sm text-gray-600 mb-2">
                  {nomination.votes} {nomination.votes === 1 ? 'stemme' : 'stemmer'}
                </p>
                {nomination.imageUrl && (
                  <img
                    src={nomination.imageUrl}
                    alt={`Nominasjon for ${nomination.name}`}
                    className="max-w-full h-auto object-cover rounded-md mb-4"
                  />
                )}
                <button
                  onClick={() => handleVote(nomination.id)}
                  disabled={isLoadingVote || remainingVotes <= 0} // Disable if loading or no remaining votes
                  className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 disabled:bg-blue-300 transition-colors duration-200 ease-in-out"
                >
                  {isLoadingVote ? "Stemmer..." : "Gi din stemme"}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}

      {activeTab === 'upload' && (
        <div className="min-h-screen">
          <h2 className="text-2xl font-bold mb-4">Last opp et verdig kostyme</h2>
          <form onSubmit={handleSubmit} className="space-y-4">
            <input
              type="text"
              placeholder="Hvem er det bilde av?"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              className="w-full px-3 py-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            {!image ? (
              <div className="flex space-x-2">
                <label className="flex-1 bg-blue-500 text-white py-4 px-4 rounded-md cursor-pointer hover:bg-blue-600 transition-colors duration-200 ease-in-out text-center flex items-center justify-center">
                  <input
                    type="file"
                    accept="image/*"
                    capture="user"
                    onChange={handleImageUpload}
                    className="hidden"
                  />
                  <Camera className="w-5 h-5 mr-2" />
                  Ta bilde
                </label>
                <label className="flex-1 bg-blue-500 text-white py-4 px-4 rounded-md cursor-pointer hover:bg-blue-600 transition-colors duration-200 ease-in-out text-center flex items-center justify-center">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageUpload}
                    className="hidden"
                  />
                  <Plus className="w-5 h-5 mr-2" />
                  Velg bilde
                </label>
              </div>
            ) : (
              <div className="flex items-center space-x-4">
                <img
                  src={URL.createObjectURL(image)}
                  alt="Nominert kostyme"
                  className="w-20 h-20 object-cover rounded-sm border-[1px] border-black"
                />
                <button
                  type="button"
                  onClick={() => setImage(null)}
                  className=" text-white py-1 px-1 hover:bg-red-600 transition-colors duration-200 ease-in-out flex items-center justify-center"
                >
                  <div className="flex items-center justify-center bg-red-500 rounded-md py-1 px-1">
                    <X className="w-5 h-5" />
                  </div>
                  <span className="text-sm text-gray-600 ml-2">Fjern bilde</span>
                </button>
              </div>
            )}
            {image && (
              <button
                type="submit"
                disabled={isUploading}
                className="w-full flex flex-row items-center justify-center bg-blue-500 text-white py-4 px-4 rounded-md hover:bg-green-600 disabled:bg-green-300 transition-colors duration-200 ease-in-out"
              >
                  <Upload className="w-5 h-5 mr-2" />
                  {isUploading ? "Laster opp..." : "Last opp"}
              </button>
            )}
          </form>
        </div>
      )}
    </div>
    </>
  )
}