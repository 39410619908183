// import "dotenv/config";
import "./App.css";
import { User } from "firebase/auth";
import {
  addDoc,
  collection,
  CollectionReference,
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
} from "firebase/firestore";
import { useState, useEffect } from "react";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, storage } from "./services/firebase";
import { ArrowsClockwise, Camera, Plus, UploadSimple, X } from "phosphor-react";
import { useCollectionData } from "react-firebase-hooks/firestore";
const { v4: uuidv4 } = require('uuid');
interface ImageGalleryProps {
  user: User | null;
}

interface ImageElement {
  imageUrl: string;
  timestamp: number;
  userName: string;
}

const imageGalleryConverter: FirestoreDataConverter<ImageElement> = {
  toFirestore(ImageElement: WithFieldValue<ImageElement>): DocumentData {
    const { ...rest } = ImageElement;
    return rest;
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): ImageElement {
    const data = snapshot.data(options) as ImageElement;
    return {
      imageUrl: data.imageUrl,
      timestamp: data.timestamp,
      userName: data.userName,
    };
  },
};

export const imageGalleryRef = collection(db, "imageGallery2024").withConverter(
  imageGalleryConverter
) as CollectionReference<ImageElement>;

function ImageGalleryMobile({ user }: ImageGalleryProps) {
  const [selectedImage, setSelectedImage] = useState<File | undefined>();
  const [isUploading, setIsUploading] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const [data] = useCollectionData<ImageElement>(imageGalleryRef);
  const firebaseImageGalleryElements = data ?? [];

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (!fileList) {
      return;
    }
    setSelectedImage(fileList[0]);
  };

  const handleUpload = () => {
    let file = selectedImage;
    if (!file) {
      return;
    }
    setIsUploading(true);
    const uniqueFileName = `${Date.now()}-${uuidv4()}-${file.name}`;
    console.log("uniqueFileName");
    const imageRef = ref(storage, `gallery/2024/${uniqueFileName}`);
    uploadBytes(imageRef, file)
      .then((snapshot) => {
        getDownloadURL(imageRef).then((url) => {
          addDoc(imageGalleryRef, {
            imageUrl: url,
            timestamp: Date.now(),
            userName: user?.displayName || "",
          }).then(() => {
            setSelectedImage(undefined);
            setIsUploading(false);
          });
        });
      })
      .catch((e) => {
        setIsUploading(false);
        console.log("Error uploading image", e);
      });
  };

  const controlNavbar = () => {
    if (typeof window !== 'undefined') {
      if (window.scrollY > (lastScrollY) && window.scrollY > 300) { // if scroll down more than 200px, hide the navbar
        setIsVisible(false);
      } else { // if scroll up show the navbar
        setIsVisible(true);
      }
      setLastScrollY(window.scrollY);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', controlNavbar);

      return () => {
        window.removeEventListener('scroll', controlNavbar);
      };
    }
  }, [lastScrollY]);

  return (
    <div className="">
      <div className="w-full pb-[130px] min-h-screen max-md:justify-start md:h-screen md:max-screen overflow-hidden bg-gradient-to-t from-[#e5d787] via-[#e5d787] to-[#12879d] flex flex-col md:flex-row flex-wrap justify-center p-2">
        {firebaseImageGalleryElements
          .sort((a, b) => b.timestamp - a.timestamp)
          .map((image) => {
            const time: string = new Date(image.timestamp)
              .toLocaleTimeString()
              .slice(0, -3);
            return (
              <div className="w-full md:max-w-[50%] md:w-auto md:h-[48%] my-2 md:mx-2 rounded-lg overflow-hidden">
                <img
                  key={"image" + image.imageUrl}
                  className="w-full md:h-full object-contain"
                  src={image.imageUrl}
                  alt=""
                />
                <div className="h-10 z-20 w-full bg-black text-white flex flex-row justify-between items-center px-2">
                  <div>{image.userName ?? ""}</div>
                  <div>{new Date(image.timestamp).toLocaleTimeString('no-NO', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false })}</div>
                </div>
              </div>
            );
          })}
      </div>
      <div className={`fixed bottom-0 py-4 space-y-2 w-full min-h-[100px] flex flex-col items-center rounded-t-3xl justify-center bg-[#FADA6D] shadow-2xl border-t-[1px] border-black transition-transform duration-300 ${isVisible ? 'translate-y-0' : 'translate-y-full'}`}>
        {!selectedImage ? (
          <>
          <div>Del dine minner fra kvelden!</div>
          <div className="flex w-full px-2 space-x-2">
            <label className="flex-1 bg-blue-500 text-white py-4 px-4 rounded-md cursor-pointer hover:bg-blue-600 transition-colors duration-200 ease-in-out text-center flex items-center justify-center">
              <input
                type="file"
                accept="image/*"
                capture="user"
                onChange={handleChange}
                className="hidden"
              />
              <Camera className="w-5 h-5 mr-2" />
              Ta bilde
            </label>
            <label className="flex-1 bg-blue-500 text-white py-4 px-4 rounded-md cursor-pointer hover:bg-blue-600 transition-colors duration-200 ease-in-out text-center flex items-center justify-center">
              <input
                type="file"
                accept="image/*"
                onChange={handleChange}
                className="hidden"
              />
              <Plus className="w-5 h-5 mr-2" />
              Velg bilde
            </label>
          </div>
          </>
        ) : (
          <div className="flex w-3/4 items-center justify-start space-x-4">
            <img
              src={URL.createObjectURL(selectedImage)}
              alt="Nominert kostyme"
              className="w-20 h-20 object-cover rounded-sm border-[1px] border-black"
            />
            <button
              type="button"
              onClick={() => setSelectedImage(undefined)}
              className=" text-white py-1 px-1 hover:bg-red-600 transition-colors duration-200 ease-in-out flex items-center justify-center"
            >
              <div className="flex items-center justify-center bg-red-500 rounded-md py-1 px-1">
                <X className="w-5 h-5" />
              </div>
              <span className="text-sm text-gray-600 ml-2">Fjern bilde</span>
            </button>
          </div>
        )}
        {selectedImage && (
          <div className="w-3/4 flex flex-row items-center justify-between">
            <button
              onClick={handleUpload}
              disabled={isUploading}
              className="w-full flex flex-row items-center justify-center bg-blue-500 text-white py-4 px-4 rounded-md hover:bg-blue-500 disabled:bg-slate-300 transition-colors duration-200 ease-in-out"
            >
              <UploadSimple className="w-5 h-5 mr-2" />
              {isUploading ? "Laster opp.." : "Last opp bilde"}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default ImageGalleryMobile;
