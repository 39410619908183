import { memo } from "react";

interface ImageElement {
  imageUrl: string;
  timestamp: number;
  userName: string;
}

interface ImageProps {
  image: ImageElement;
  i: number;
  isActive: boolean;
  key: number;
}

const Image = ({ image, i, isActive, key }: ImageProps) => {
  const time: string = new Date(image.timestamp)
    .toLocaleTimeString()
    .slice(0, -3);
  //create random number between -20 and 20 based on index
  // const random = Math.floor(Math.random() * 40) - 20;
  const random = Math.min(Math.max(i * 1, 1), 20);
  //If even number, rotate clockwise, else rotate counter clockwise
  const rotate = i % 2 === 0 ? random : -random;
  console.log("i", i);
  console.log("rotate", rotate);

  return (
    <>
      <div
        key={image.imageUrl + "image" + i + rotate}
        style={{
          zIndex: isActive ? 999 : i,
          opacity: isActive ? 1 : 0.5,
          border: "10px solid white",
          rotate: `${rotate}deg`,
        }}
        className={`max-w-[50vw] md:h-[80vh] min-h-[50vh] my-8 md:mx-2 rounded-lg flex flex-col justify-end absolute bottom-0 ${
          isActive && "shadow-2xl"
        }`}
      >
        <img
          key={"image" + image.imageUrl}
          className="h-[95%] object-contain bg-white"
          src={image.imageUrl}
          alt=""
        />
        <div className="h-10 z-20 w-full bg-black text-white flex flex-row justify-between items-center px-2">
          <div>{image.userName ?? ""}</div>
          <div>{new Date(image.timestamp).toLocaleTimeString('en-US', { hour12: false })}</div>
        </div>
      </div>
    </>
  );
};

export default memo(Image);
